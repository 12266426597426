<template lang="pug">
v-autocomplete(
  :value="value"
  @input="$emit('input', $event)"
  :multiple="multiple"
  :clearable="clearable"
  hide-details
  :items="model.options[field].list"
  :loading="model.options[field].loading"
  :disabled="model.options[field].loading"
  :label="label"
  :item-text="itemText",
  :item-value="itemValue"
  small
  :menu-props="{contentClass: 'menu-with-scroll-visible'}"
  @mouseup="closeSelectIfOpen($refs.select, {enabled: closeOnInputClick})"
  ref="select"
)
  template(v-slot:append v-if="hasValue")
    v-icon(color="primary") mdi-check-circle
  template(v-slot:prepend-item v-if="showCloseMenuBtn")
    select-close-btn(@click.native="closeSelect($refs.select)")
</template>

<script>
import AutocompleteMixin from '@/app/admin/mixins/autocomplete.mixin'

export default {
  mixins: [AutocompleteMixin],
  props: {
    model: {
      type: Object,
      required: true
    },
    value: {
      required: true
    },
    field: {
      type: String,
      required: true
    },
    label: String,
    itemText: String,
    itemValue: String,
    multiple: Boolean,
    clearable: Boolean,
    showCloseMenuBtn: Boolean,
    closeOnInputClick: Boolean,
  },

  computed: {
    hasValue() {
      if(!this.multiple && this.value) return true
      if(this.multiple && this.value.length) return true
      return false
    }
  },

  components: {
    selectCloseBtn: () => import('@/app/admin/components/SelectCloseBtn.vue'),
  }
}
</script>
